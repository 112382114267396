import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
// import logo1 from '../Image/logo1.png'
import { Link } from 'react-router-dom'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import axios from 'axios'
// import {AppSidebar, AppHeader } from '../../../components/index'
import College from '../views/pages/collegeChoice/College'
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
function CollegeList(props) {
    // ***************************** user Authentication *******************************

    const [collegeChoise, setCollegeChoice] = useState([])
    const [studentData, setStudentData] = useState({});
    const Navigate = useNavigate()

    const callStudentDashboardPage = async () => {
        try {
            const res = await fetch('/student-dashboards',
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    credentials: "include"
                }
            )
            const data = await res.json()
            // console.log(data)
            setStudentData(data)

            if (!res.status === 401) {
                const error = new Error(res.server)
                throw error;
            }
        } catch (error) {
            // console.log("unouthorized")
            Navigate('/login')
        }
    }

    // useEffect(() => {
    //     callStudentDashboardPage()
    // }, [])
    // console.log(collegeChoise)


    // ***********************************college choice *********************************

    const [state, setState] = React.useState('');
    const [programme, setProgramme] = React.useState('');
    // const [programme, setProgramme] = React.useState(props.data);
    const [collegeData, setCollegeData] = React.useState([])
    const [prog, setProg] = React.useState('')
    // console.log({data:props.data})
    const [branch, setBranch] = React.useState("")
    // console.log(state)
    // console.log(collegeData)


    const getData = () => {

        axios.get('/api/v1/profile')
            .then(function (response) {
                // handle success

                setProgramme(response.data.user.examination_details.prog_opted)
                // console.log(response.data.user)
            })
            .catch(function (error) {
                // handle error

                console.log(error);
            })
    }
    useEffect(() => {
        getData()
    }, [])

    // **************************** COLLEGE SEARCH ********************************

    const [selectBranch, setSelectBranch] = React.useState("")
    const handleSubmit = async (event) => {
        event.preventDefault()
        if (programme == "Aeronoutical Engineering") {
            const response = await fetch('/Aeronoutical_Engineering', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    state,
                    programme,
                })
            })
            const data = await response.json()

            // console.log(data.message)
            if (data.message === 'no result found') {
                alert('no result found')
            }
            else if (data.message === 'please filled all details') {
                alert('please select option')
            }
            else {
                setCollegeData(data.message)
                setProg(programme)

            }
        }
        else if (programme == "Bsc.Agriculture") {
            const response = await fetch('/Agriculture', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    state : state == " " ? null : state,
                    programme: "Agriculture",
                })
            })
            const data = await response.json()

            // console.log(data.message)
            if (data.message === 'no result found') {
                setCollegeData("")
                alert('no result found')
            }
            else if (data.message === 'please filled all details') {
                alert('please select option')
            }
            else {
                setCollegeData(data.message)
                setProg(programme)
            }
        }
        else if (programme == "B.Design") {
            const response = await fetch('/B_Design', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    state: state == " " ? null : state.toUpperCase(),
                    programme,
                })
            })
            const data = await response.json()

            // console.log(data.message)
            if (data.message === 'no result found') {
                setCollegeData("")
                alert('no result found')
            }
            else if (data.message === 'please filled all details') {
                alert('please select option')
            }
            else {
                setCollegeData(data.message)
                setProg(programme)
            }
        }
        else if (programme == "BBA") {
            const response = await fetch('/Bba', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    state: state == " " ? null : state.toUpperCase(),
                    programme,
                })
            })
            setBranch("")
            const data = await response.json()

            // console.log(data.message)
            if (data.message === 'no result found') {
                setCollegeData("")
                alert('no result found')
            }
            else if (data.message === 'please filled all details') {
                alert('please select option')
            }
            else {
                setCollegeData(data.message)
                setProg(programme)
            }
        }
        else if (programme == "Bio Technology ( Bsc. BioTechnology / B-Tech BioTechnology )") {
            const response = await fetch('/Bio_Technology', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    state: state == " " ? null : state.toUpperCase(),
                    programme: "Bio-Technology",
                })
            })
            const data = await response.json()

            // console.log(data.message)
            if (data.message === 'no result found') {
                setCollegeData("")
                alert('no result found')
            }
            else if (data.message === 'please filled all details') {
                alert('please select option')
            }
            else {
                setCollegeData(data.message)
                setProg(programme)
            }
        }
        else if (programme == "Bsc. Nursing") {
            const response = await fetch('/Bsc_Nursing', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    state: state == " " ? null : state.toUpperCase(),
                    programme,
                })
            })
            const data = await response.json()

            // console.log(data.message)
            if (data.message === 'no result found') {
                setCollegeData("")
                alert('no result found')
            }
            else if (data.message === 'please filled all details') {
                alert('please select option')
            }
            else {
                setCollegeData(data.message)
                setProg(programme)
            }
        }
        else if (programme == "engg") {
            const response = await fetch('/Engineering', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    state : state == " " ? "" : state.toUpperCase(),
                    programme: "ENGINEERING",
                    branch,
                })
            })
            const data = await response.json()

            // console.log(data.message)
            if (data.message === 'no result found') {
                setCollegeData("")
                alert('no result found')
            }
            else if (data.message === 'please filled all details') {
                alert('please select option')
            }
            else {
                setCollegeData(data.message)
                setProg(programme)
                setSelectBranch(branch)
            }


        }
        else if (programme == "B.A, B.COM ,B.sc") {
            const response = await fetch('/ba-bcom-bsc', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    state: state == " " ? null : state.toUpperCase(),
                    programme: "B.A, B.COM ,B.sc",
                })
            })
            const data = await response.json()

            // console.log(data.message)
            if (data.message === 'no result found') {
                setCollegeData("")
                alert('no result found')
            }
            else if (data.message === 'please filled all details') {
                alert('please select option')
            }
            else {
                setCollegeData(data.message)
                setProg(programme)
            }
        }
        else if (programme == "Hotel Managenment") {
            const response = await fetch('/Hotel_Managenment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    state,
                    programme,
                })
            })
            const data = await response.json()

            // console.log(data.message)
            if (data.message === 'no result found') {
                setCollegeData("")
                alert('no result found')
            }
            else if (data.message === 'please filled all details') {
                alert('please select option')
            }
            else {
                setCollegeData(data.message)
                setProg(programme)
            }
        }
        else if (programme == "BCA") {
            const response = await fetch('/Law', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    state: state == " " ? null : state.toUpperCase(),
                    programme,
                })
            })
            const data = await response.json()

            // console.log(data.message)
            if (data.message === 'no result found') {
                setCollegeData("")
                alert('no result found')
            }
            else if (data.message === 'please filled all details') {
                alert('please select option')
            }
            else {
                setCollegeData(data.message)
                setProg(programme)
            }
        }
        else if (programme == "LAW") {
            const response = await fetch('/Law', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    state: state == " " ? null : state.toUpperCase(),
                    programme:"LLB",
                })
            })
            const data = await response.json()

            // console.log(data.message)
            if (data.message === 'no result found') {
                setCollegeData("")
                alert('no result found')
            }
            else if (data.message === 'please filled all details') {
                alert('please select option')
            }
            else {
                setCollegeData(data.message)
                setProg(programme)
            }
        }
        else if (programme == "Mass Communication & Journalism") {
            const response = await fetch('/Mass_Communication', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    state: state == " " ? null : state.toUpperCase(),
                    programme,
                })
            })
            const data = await response.json()

            // console.log(data.message)
            if (data.message === 'no result found') {
                setCollegeData("")
                alert('no result found')
            }
            else if (data.message === 'please filled all details') {
                alert('please select option')
            }
            else {
                setCollegeData(data.message)
                setProg(programme)
            }
        }
        else if (programme == "MBA/PGDM") {
            const response = await fetch('/Mca', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    state: state == " " ? null : state.toUpperCase(),
                    programme,
                })
            })
            const data = await response.json()

            // console.log(data.message)
            if (data.message === 'no result found') {
                setCollegeData("")
                alert('no result found')
            }
            else if (data.message === 'please filled all details') {
                alert('please select option')
            }
            else {
                setCollegeData(data.message)
                setProg(programme)
            }
        }
        else if (programme == "B.Pharmacy") {
            const response = await fetch('/Pharmacy', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    state: state == " " ? null : state.toUpperCase(),
                    programme: "B.Pharmacy",
                })
            })
            const data = await response.json()

            // console.log(data.message)
            if (data.message === 'no result found') {
                setCollegeData("")
                alert('no result found')
            }
            else if (data.message === 'please filled all details') {
                alert('please select option')
            }
            else {
                setCollegeData(data.message)
                setProg(programme)
            }
        }
        else if (programme == "LLB (BA / B.Com / B.sc / BBA / BCA)") {
            const response = await fetch('/llb', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    state: state == " " ? null : state.toUpperCase(),
                    programme: "LLB (BA / B.Com / B.sc / BBA / BCA)",
                })
            })
            const data = await response.json()

            // console.log(data.message)
            if (data.message === 'no result found') {
                setCollegeData("")
                alert('no result found')
            }
            else if (data.message === 'please filled all details') {
                alert('please select option')
            }
            else {
                setCollegeData(data.message)
                setProg(programme)
            }
        }
    }
    // console.log(branch)

    const NextStep = async () => {

        if (a.length >= 5) {
            const res = await fetch('/choice-filling', {
                method: "POST",
                headers: {
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify({ collegeName: a, collegeCode: b, programme: c, state: d, seat: e, branch: f })
            })
            const data = await res.json();
            if (!data) {
                // console.log("Please try again after some time")
                alert('Please try again after some time')
            }
            else {
                // console.log("Your college has been added sucessfully")
                alert('Your college has been added sucessfully')
                Navigate('/')

            }
        }
        else {
            alert('please add atleast 25 colleges')
        }
    }



    // *******************thapaaaaaaaa*************
    const [a, setA] = useState([])
    const [b, setB] = useState([])
    const [c, setC] = useState([])
    const [d, setD] = useState([])
    const [e, setE] = useState([])
    const [f, setF] = useState([])
    const collegeChoiceHandle = (collegeName, collegeCode, program, state, seat, branch) => {
        const arr = []
        const index = a.indexOf(collegeName)
        arr.push(index)
        //    console.log(a[arr])
        const branchArr = []
        const branchIndex = f.indexOf(branch)
        branchArr.push(branchIndex)
        //    console.log(f[branchArr])
        if (a[arr] == undefined || f[branchArr] == undefined) {
            setA([...a, collegeName])
            setB([...b, collegeCode])
            setC([...c, program])
            setD([...d, state])
            setE([...e, seat])
            setF([...f, branch])
        }
        else {
            alert("This college has been already exist")
        }
    }
    // console.log(a)
    // console.log(b)
    // console.log(c)
    // console.log(d)
    // console.log(e)
    const removeCollege = (idx) => {
        // console.log(idx)
        setA((e) => {
            return e.filter((elem, index) => {
                return index !== idx
            })
        })
        setB((e) => {
            return e.filter((elem, index) => {
                return index !== idx
            })
        })
        setC((e) => {
            return e.filter((elem, index) => {
                return index !== idx
            })
        })
        setD((e) => {
            return e.filter((elem, index) => {
                return index !== idx
            })
        })
        setE((e) => {
            return e.filter((elem, index) => {
                return index !== idx
            })
        })
        setF((f) => {
            return f.filter((elem, index) => {
                return index !== idx
            })
        })

    }
    // **************************  check autohorization *****************************
    const allDataStudent = async () => {
        fetch('/students-data')
            .then(response => response.json())
            .then(data => {
                //   console.log(data)
                // setAllData(data)
                if (data.collegeChoiceFilling.length != 0) {
                    // Navigate('/document-upload')
                }
            })
            .catch(error => {
                // console.log(error)
            })
    }
    // allDataStudent()
    return (
        <>
            <College>
                {/* <AppSidebar /> */}
                <div className="" >
                    {/* <AppHeader /> */}
                    <div className="body flex-grow-1 px-3" >
                        <div style={{ width: '100%', height: '70vh', overflow: 'scroll' }} className="studentDashboardContainer">
                            <center className='collegeSearch'>
                                <div className='clgsearch'>
                                    <div>
                                        <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
                                            <InputLabel style={{ fontWeight: 'bold' }} id="demo-select-small">All States</InputLabel>
                                            <Select
                                                labelId="demo-select-small"
                                                id="demo-select-small"
                                                value={state}
                                                label="State"
                                                style={{ backgroundColor: 'white' }}
                                                onChange={(e) => setState(e.target.value)}
                                            >
                                                
                                                <MenuItem value=" " selected >All States</MenuItem>
                                                <MenuItem value="Andhra Pradesh">Andhra Pradesh</MenuItem>
                                                <MenuItem value="ARUNACHAL PRADESH">Arunachal Pradesh</MenuItem>
                                                <MenuItem value="Assam">Assam</MenuItem>
                                                <MenuItem value="Bihar">Bihar</MenuItem>
                                                <MenuItem value="Punjab">Punjab</MenuItem>
                                                <MenuItem value="Chattisgarh">Chattisgarh</MenuItem>
                                                <MenuItem value="Goa">Goa</MenuItem>
                                                <MenuItem value="Gujarat">Gujarat</MenuItem>
                                                <MenuItem value="Haryana">Haryana</MenuItem>
                                                <MenuItem value="Himanchal Pradesh">Himanchal Pradesh</MenuItem>
                                                <MenuItem value="Jharkhand">Jharkhand</MenuItem>
                                                <MenuItem value="Karnataka">Karnataka</MenuItem>
                                                <MenuItem value="Kerala">Kerala</MenuItem>
                                                <MenuItem value="Madhya Pradesh">Madhya Pradesh</MenuItem>
                                                <MenuItem value="Maharashtra">Maharashtra</MenuItem>
                                                <MenuItem value="Manipur">Manipur</MenuItem>
                                                <MenuItem value="Meghalaya ">Meghalaya</MenuItem>
                                                <MenuItem value="Mizoram">Mizoram</MenuItem>
                                                <MenuItem value="Nagaland ">Nagaland</MenuItem>
                                                <MenuItem value="Odisha">Odisha</MenuItem>
                                                <MenuItem value="Punjab">Punjab</MenuItem>
                                                <MenuItem value="Rajasthan">Rajasthan</MenuItem>
                                                <MenuItem value="Sikkim">Sikkim</MenuItem>
                                                <MenuItem value="Tamil Nadu">Tamil Nadu</MenuItem>
                                                <MenuItem value="Telangana">Telangana</MenuItem>
                                                <MenuItem value="Tripura">Tripura</MenuItem>
                                                <MenuItem value="Uttar Pradesh">Uttar Pradesh</MenuItem>
                                                <MenuItem value="Uttrakhand">Uttrakhand</MenuItem>
                                                <MenuItem value="West Bengal">West Bengal</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div>
                                        <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
                                            <InputLabel style={{ fontWeight: 'bold' }} id="demo-select-small">Programme</InputLabel>
                                            <Select
                                                labelId="demo-select-small"
                                                id="demo-select-small"
                                                value={programme}
                                                label="Programme"
                                                style={{ backgroundColor: 'white' }}
                                                // onChange={(e) => setProgramme(e.target.value)}
                                                disabled
                                            // disabled
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {/* <MenuItem value="Aeronoutical Engineering">Aeronoutical Engineering</MenuItem>
                                            <MenuItem value="Agriculture">Agriculture</MenuItem>
                                            <MenuItem value="B_Design">B Design</MenuItem>
                                            <MenuItem value="BBA">BBA</MenuItem>
                                            <MenuItem value="Bio Technology">Bio Technology</MenuItem>
                                            <MenuItem value="Bsc Nursing">Bsc Nursing</MenuItem> */}
                                                <MenuItem value="Bsc.Agriculture"> Bsc Agriculture</MenuItem>
                                                <MenuItem value="Bio Technology ( Bsc. BioTechnology / B-Tech BioTechnology )">Bio Technology ( Bsc. BioTechnology / B-Tech BioTechnology )</MenuItem>
                                                <MenuItem value="engg">Engineering</MenuItem>
                                                <MenuItem value="B.Pharmacy">B.Pharmacy</MenuItem>
                                                <MenuItem value="B.Design">B.Design</MenuItem>
                                                <MenuItem value="MBA/PGDM">MBA/PGDM</MenuItem>
                                                <MenuItem value="BCA">BCA</MenuItem>
                                                <MenuItem value="BBA">BBA</MenuItem>
                                                <MenuItem value="B.A, B.COM ,B.sc">B.A, B.COM ,B.sc</MenuItem>
                                                <MenuItem value="LLB (BA / B.Com / B.sc / BBA / BCA)">LLB (BA / B.Com / B.sc / BBA / BCA)</MenuItem>
                                                <MenuItem value="LAW">LAW</MenuItem>
                                                <MenuItem value="Mass Communication & Journalism">Mass Communication & Journalism</MenuItem>
                                                <MenuItem value="Bsc. Nursing">Bsc. Nursing</MenuItem>
                                                {/* <MenuItem value="Hotel Managenment">Hotel Managenment</MenuItem>
                                            <MenuItem value="Law">Law</MenuItem>
                                            <MenuItem value="Mass Communication">Mass Communication</MenuItem>
                                            <MenuItem value="Mca">MCA</MenuItem>
                                            <MenuItem value="Pharmacy">Pharmacy</MenuItem> */}

                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div>
                                        {
                                            programme == "engg" ? <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
                                                <InputLabel style={{ fontWeight: 'bold' }} id="demo-select-small">Branch</InputLabel>
                                                <Select
                                                    labelId="demo-select-small"
                                                    id="demo-select-small"
                                                    value={branch}
                                                    label="Branch"
                                                    style={{ backgroundColor: 'white', outline: 'none' }}
                                                    onChange={(e) => setBranch(e.target.value)}
                                                // disabled
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value="Computer science Engineering">Computer science Engineering </MenuItem>
                                                    <MenuItem value="Computer Science Engineering ( Block Chain Technology )">Computer Science Engineering ( Block Chain Technology )</MenuItem>
                                                    <MenuItem value="Computer Science Engineering ( Cloud Computing )">Computer Science Engineering ( Cloud Computing )</MenuItem>
                                                    <MenuItem value="Computer Science Engineering ( Cyber Security  )">Computer Science Engineering ( Cyber Security  )</MenuItem>
                                                    <MenuItem value="Computer Science Engineering ( Full stack Development  )">Computer Science Engineering ( Full stack Development  )</MenuItem>
                                                    <MenuItem value="Computer Science Engineering ( Internet of Things   )">Computer Science Engineering ( Internet of Things   )</MenuItem>
                                                    <MenuItem value="Artificial Intelligence & Machine Learining">Artificial Intelligence & Machine Learining</MenuItem>
                                                    <MenuItem value="Information Technology">Information Technology</MenuItem>
                                                    <MenuItem value="Artificial Intelligence & Data Science">Artificial Intelligence & Data Science</MenuItem>
                                                    <MenuItem value="Information Technology - Data Analytics">Information Technology - Data Analytics</MenuItem>
                                                    <MenuItem value="Civil Engineering">Civil Engineering</MenuItem>
                                                    <MenuItem value="Electrical & Electronics Engineering">Electrical & Electronics Engineering</MenuItem>
                                                    <MenuItem value="Electronics & Communication engineering">Electronics & Communication engineering</MenuItem>
                                                    <MenuItem value="Mechanical Engineering">Mechanical Engineering</MenuItem>
                                                    <MenuItem value="Computer Science Engineering ( AI & ML  )">Computer Science Engineering ( AI & ML  )</MenuItem>
                                                    <MenuItem value="Computer Science Engineering ( Data Science  )">Computer Science Engineering ( Data Science  )</MenuItem>
                                                </Select>
                                            </FormControl> : <></>
                                        }
                                    </div>
                                    <div>
                                        {
                                            programme == "Hotel Managenment" ? <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
                                                <InputLabel style={{ fontWeight: 'bold' }} id="demo-select-small">Branch</InputLabel>
                                                <Select
                                                    labelId="demo-select-small"
                                                    id="demo-select-small"
                                                    value={branch}
                                                    label="Branch"
                                                    style={{ backgroundColor: 'white' }}
                                                    onChange={(e) => setBranch(e.target.value)}
                                                // disabled
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value="BHMCT">BHMCT</MenuItem>
                                                    <MenuItem value="BHM">BHM</MenuItem>

                                                </Select>
                                            </FormControl> : <></>
                                        }
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                <FormControl sx={{ minWidth: 100 }} size="small">
                                    <Button disabled={ !programme} onClick={handleSubmit} style={{ backgroundColor: '#042e2e', color: 'white' }} variant="contained" component="span">
                                        Search
                                    </Button>
                                </FormControl>
                            </center>
                            <div className=' mt-5' style={{ width: '100%' }}>
                                {collegeData.length == 0 ? <></> : <div className='counsellingTableParent'>
                                    <MDBTable responsive className='counsellingTable'  >
                                        <MDBTableHead>
                                            <tr className='counsellingTable1' style={{ width: '100%' }} >
                                                <th className='counsellingTable2' scope='col'>S.L</th>
                                                <th className='counsellingTable3' scope="col">College Name</th>
                                                <th className='counsellingTable4' scope="col">College Code</th>
                                                <th className='counsellingTable5' scope="col">Programme</th>
                                                {selectBranch == "" ? <></> : <th className='counsellingTable6' scope="col">Branch</th>}
                                                <th className='counsellingTable7' scope="col">State</th>
                                                <th className='counsellingTable7' scope="col">Action</th>
                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody>

                                            {
                                                collegeData.map((e, i) => {
                                                    let arr = []
                                                    if (selectBranch != "") {
                                                        arr.push(e.branches.indexOf(selectBranch))
                                                    }

                                                    return (
                                                        <>
                                                            <tr key={e.collegeCode} style={{ fontSize: '0.8rem' }}>
                                                                <td>{i + 1}</td>
                                                                <td>{e.collegeName}</td>
                                                                <td>{e.collegeCode}</td>
                                                                <td>{e.programme}</td>
                                                                {selectBranch == "" ? <></> : <td>{e.branches[arr]}</td>}
                                                                <td>{e.state}</td>
                                                                {
                                                                    selectBranch == "" ? <td onClick={() => props.onClick(e, null)}><button style={{ backgroundColor: '#1a6df6', color: 'white', border: 'none', borderRadius: '3px' }}>ADD</button></td> :
                                                                        <td onClick={() => props.onClick(e, e.branches[arr])}><button style={{ backgroundColor: '#1a6df6', color: 'white', border: 'none', borderRadius: '3px' }}>ADD</button></td>
                                                                }


                                                            </tr>
                                                        </>
                                                    )
                                                })
                                            }

                                        </MDBTableBody>
                                    </MDBTable>


                                </div>
                                }


                            </div>
                        </div>
                    </div>
                    {/* <AppFooter /> */}
                </div>
            </College>






        </>
    )
}

export default CollegeList