import React from 'react'
import styled from 'styled-components'
import { MDBCard, MDBCardBody, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
function ResultMessage() {
    return (
        <>
            <MDBCard>
                <div style={{ padding: '15px 15px' }}>
                    <Mess>
                        <div>
                            <p className='heading'>Dear Candidate,</p>
                            <p className='headingMess '>
                                Based on your Rank and Choices of Program you have been provisionally alloted a seat in the Academic Program and Institute shown Above.
                            </p>
                            <p className='headingMess para'>
                                You are required to Complete the following Steps ( if you are selecting the confirmation option of either <strong>Freeze</strong> or <strong>Float</strong> ) otherwise the Alloted seat would be Cancelled.
                            </p>
                            <p className='headingMess' style={{ fontWeight: 'bold' }}>
                                A. &nbsp; Uploading of Documents
                            </p>
                            <p className='headingMess' >
                                <li style={{ marginLeft: '1.8rem' }}> Class X Marksheet</li><br></br>
                                <li style={{ marginLeft: '1.8rem', marginTop: '-1.6rem' }}> Aadhaar Card (Both Sides)</li><br></br>
                                <li style={{ marginLeft: '1.8rem', marginTop: '-1.6rem' }}> Class XII Marksheet or equivalent .( You don't need to upload the marksheet If you are Appearing in 2025 and your 12th Result has Not Came yet ) .</li>
                            </p>
                            <p className='headingMess' style={{ fontWeight: 'bold' }}>
                                B. &nbsp; A payment of INR 15,000/- will be charged towards seat Acceptance and Partial First year tuition fee of the allotted Institute.
                            </p>
                            <p className='headingMess' style={{ fontWeight: 'bold' }}>
                                C. &nbsp; There is no need to upload any documents if you are selecting the confirmation option of 'Slide'.
                            </p>
                            {/* <p className='headingMess' style={{ fontWeight: 'semibold',fontSize:'0.9rem',color:'red' }}>
                        On requests from various students. The Last date to Confirm your Allotted seat and Pay Seat acceptance fee & Partial tuition fee of INR 25,000 /-  has been extended till 21st April, 2023(5 PM). Remember this is the Last Round of college Allotment.
                        </p> */}
                            {/* <p style={{ textAlign: 'center', color: 'red', fontWeight: '600', fontSize: '13px' }}>The Last date to Upload the documents & Payment of Seat Acceptance is</p> */}
                        </div>
                    </Mess>
                </div>
            </MDBCard>
        </>

    )
}

const Mess = styled.main`
    .heading{
        /* font-weight: bold; */
    }
    .headingMess{
        /* margin-left: 5rem; */
        margin-top: -0.7rem;
        text-align: justify;
    }
    .para{
        margin-top: -16px;
    }
    @media only screen and (max-width: 600px){
        .headingMess{
        /* margin-left: 2rem; */
        margin-top: -0.7rem;
    }


    }
`

export default ResultMessage