import React from 'react'

const RefundRules = () => {
  return (
    <div>
      <div style={{overflow:'scroll'}}>
        <iframe src='https://aicee.s3.ap-south-1.amazonaws.com/counselling/AICEE+2025+REFUND+RULES.pdf' width="100%" style={{margin:'auto'}} height="800px" frameborder="0"></iframe>
        </div>
    </div>
  )
}

export default RefundRules