import React, { useState, useEffect } from 'react'
import Dashboard from '../components/spotDashboard/Dashboard'
import CounsellingPayment from './CounsellingPayment'
import Message from './Message'
import axios from 'axios'
import Spinner from './Spinner'
import CollegeChoice from './ChoiceFilling'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom'



function Home() {
  const [data, setData] = useState({})
  const [spin, setSpin] = useState(false)
  const [open, setOpen] = React.useState(true);
  const [paymentStatus, setPaymentStatus] = useState()
  const navigate = useNavigate()
  const getData = () => {
    setOpen(true)
    axios.get('/api/v1/profile')
      .then(function (response) {
        // handle success
        setOpen(false)
        setData(response.data.user)
        if (response.data.user.college_choice.length >= 1) {
          setPaymentStatus(response.data.user.counselling_payments[0].payment_status)
          // response.data.user.college_choice[0].allot_round_two
          if (response.data.user.college_choice[0].allot_round_three || response.data.user.college_choice[0].round_one_action == 'Freeze' || response.data.user.college_choice[0].round_two_action == 'Freeze') {
            navigate('/student/dashboard/seat-confirmation')
          }

          // if (response.data.user.college_choice[0].round_one_action == 'Freeze') {
          //   navigate('/student/dashboard/seat-confirmation')
          // }
        }
        if (response.data.user.counselling_payments.length >= 1) {
          setPaymentStatus(response.data.user.counselling_payments[0].payment_status)
        }
        else {
          setPaymentStatus(false)
        }

        console.log(response.data.user)
      })
      .catch(function (error) {
        // handle error
        setOpen(false)
        console.log(error);
      })
  }


  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  useEffect(() => {
    getData()
  }, [])
  return (
    <div>
      <Dashboard />
      {
        open == false && <>

          {
            paymentStatus ? <CollegeChoice /> : <CounsellingPayment />
          }
        </>
      }

      <div>

        <Backdrop
          sx={{ color: '#0e2e2e', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          style={{ backgroundColor: 'white' }}
        // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </div>
  )
}

export default Home