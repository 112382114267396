import React, { useState, useEffect } from 'react'
import CollegeList from './CollegeList'
import CollegePreview from './CollegePreview'
import axios from 'axios'
import SubmitCollege from './SubmitCollege'
import SubmitCollegeMessage from './SubmitCollegeMessage'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import NotCollegeAllot from './NotAllotCollege'
import ChoiceFillingInstruction from './choiceFilling/ChoiceFillingInstruction'
import ExitMessage from './seatConfirmation/ExitMessage'
function ChoiceFilling() {
  // 1.Not Started 2.Started 3.Time Over 4.Choice Fill
  const [choiceFilling, setChoiceFilling] = useState('Started')
  const [action, setAction] = useState('no')
  console.log('choiceFilling', choiceFilling)
  console.log('action', action)
  const Navigate = useNavigate()
  const [college, setCollege] = React.useState([])
  const [counsellingPortal, setCounsellingPortal] = useState(false)
  const [result, setResult] = useState(true)
  function onSubmit(e, branch) {
    // alert(branch)
    console.log(e, branch)
    // console.log(e)
    if (college.some((item) => item.id === e._id && item.branch === branch)) {
      alert('This College has already been added in your list')
    } else {
      const updateCollege = [
        ...college,
        {
          id: e._id,
          collegeName: e.collegeName,
          collegeCode: e.collegeCode,
          programme: e.programme,
          state: e.state,
          branch: branch
        }
      ]
      setCollege(updateCollege)
    }
  }
  // console.log(college)


  // Function to remove College based on its id
  const removeItem = (id) => {
    setCollege(college.filter((item) => item.id !== id));
  };

  // Function to change the index of a college
  const changeIndex = (id, newIndex) => {
    const itemIndex = college.findIndex((item) => item.id === id);
    const newData = [...college];
    const removedItem = newData.splice(itemIndex, 1)[0];
    newData.splice(newIndex, 0, removedItem);
    setCollege(newData);
  };

  const [data, setData] = useState({})
  const [open, setOpen] = React.useState(true);
  //  console.log(data.progOpted)
  //  const branch = data.progOpted
  const getData = () => {
    setOpen(true)
    axios.get('/api/v1/profile')
      .then(function (response) {
        // handle success
        setOpen(false)
        setData(response.data.user)
        // if (response.data.user.allotedCollege.length >= 1) {
        //   Navigate('/student/dashboard/seat-confirmation')
        // }
        

        if (response.data.user.college_choice.length >= 1) {
          setCollege(response.data.user.college_choice[0].college)
          if(response.data.user.college_choice[0].college_submit == 'yes') {
            setAction('yes')
          }
          if(response.data.user.college_choice[0].college_submit == 'no') {
            setAction('no')
          }
          // setAction(response.data.user.college_choice[0].college_submit)
          
        }
        // console.log(response.data.user)
      })
      .catch(function (error) {
        // handle error
        setOpen(false)
        // console.log(error);
      })
  }
  useEffect(() => {
    getData()
  }, [])

  const saveCollege = () => {

    axios.post(`/api/v1/choice-filling`, {
      collegeChoice: college
    })
      .then(function (response) {
        // console.log(response);
        alert(response.data.message)
      })
      .catch(function (error) {
        // console.log(error);
        alert(error.response.data.message)
      });
  }
  const submitCollege = () => {
    axios.post(`/api/v1/choice-filling-submit`, {
      collegeChoice: college
    })
      .then(function (response) {
        // console.log(response);
        getData()
        alert('Your Choice Filled College has been saved successfully')
      })
      .catch(function (error) {
        // console.log(error);
        alert(error.response.data.message)
      });
  }

  const resetCollege = () => {
    setCollege([])
  }

  return (
    <>
      {
        choiceFilling == "Choice Fill" && <>
          {
            action != 'yes' &&
            <div style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', margin: '10px' }}>
              <CollegeList onClick={(data, branch) => onSubmit(data, branch)} data={data} />
              <CollegePreview data={data.program} previewData={college} onClick={(id) => removeItem(id)} onClicks={(id, newIndex) => changeIndex(id, newIndex)} />
              <SubmitCollege saveCollege={saveCollege}
                submitCollege={submitCollege}
                resetCollege={resetCollege}
              />
            </div>
          }
        </>
      }
{/* <SubmitCollegeMessage/> */}
      {
        action == "yes" && <SubmitCollegeMessage data={data.program} previewData={college} onClick={(id) => removeItem(id)} onClicks={(id, newIndex) => changeIndex(id, newIndex)} /> 
      }
      {/* {
        action == "no" && <ExitMessage />
      } */}
      {
        action == "no" && <ChoiceFillingInstruction action={choiceFilling} onChange={setChoiceFilling} />
      }
        {/* action == "yes" ? <SubmitCollegeMessage previewData={college} data={data.progOpted} /> : <ChoiceFillingInstruction action={choiceFilling} onChange={setChoiceFilling} /> */}






      <Backdrop
        sx={{ color: '#0e2e2e', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        style={{ backgroundColor: 'white' }}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>


    </>

  )
}


const CSS = styled.main`
.proceedButton{
  width: 80%;
    padding: 8px 15px;
    color: white;
    font-weight: 500;
    border-radius: 5px;
    margin: auto;
    text-align: center;
}
.counsellingCss{
  /* border: 2px solid black; */
  /* width: 45%;
  margin: auto; */
  margin-top: 1rem;
  text-align: justify;
  /* justify-content: start; */
  /* justify-content: start; */
  /* margin: 2rem 20rem; */
}
.counsellingCss{
  /* margin-left: 11rem; */
  padding-top: 2rem;
  width: 60%;
  margin:auto
}
.counsellingCss>li{
  margin-left: 10%;
 
}
@media only screen and (max-width: 480px){
.counsellingCss{
  text-align: justify;
  border: 1px solid black;
}
li{
  text-align: justify;
  padding-left: 1rem;
  padding-right: 1rem;
}
  /* .counsellingCss>li{
  margin-left: 0rem;
} */
  .counsellingCss{
  /* border: 2px solid black; */
  /* margin: 1rem; */
  padding: 1rem;
  width: 100%;
  text-align: justify;
  /* justify-content: start; */
  /* margin: 2rem 20rem; */
}
.counsellingButton{
  border: 3px solid black;
  width: 80%;
  margin: auto;
}
.counsellingCss>li{
  margin-left: 0rem;
}
}
@media only screen and (max-width: 1280px){
  
  .counsellingCss{
  /* border: 2px solid black; */
  /* margin: 1rem; */
  padding: 1rem;
  width: 100%;
  margin: auto;
  text-align: justify;
  /* justify-content: start; */
  /* margin: 2rem 20rem; */
}
}

.counsellingButton{
  margin: 1rem;
  padding: 0.5rem 2rem;
  color: white;
  background-color: #042e2e;
}
.counsellingButton2{
  margin: 1rem;
  padding: 0.5rem 2rem;
  color: white;
  background-color: #042e2e;
  margin-top: 12rem;
}
`

export default ChoiceFilling