import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
function ExitMessage() {
  const navigate = useNavigate()
  return (
    <Next>
      <center><p style={{ textDecoration: 'underline', fontSize: '1.2rem', marginTop: '9rem', fontWeight: 'bold' }}>
        You have Not opted the allotted seat .
      </p></center>
      <center><p style={{ fontSize: '1rem', marginTop: '1rem', fontWeight: 'bold' }}>
        Thank you for participating in the counseling rounds of AICEE 2025. <br></br>

      </p></center>
      <center><button className='counsellingButton'
      // onClick={() => { navigate('https://aicee.in/refund-login') }}
      onClick={() => { alert('Refund process will start from 20th july .') }}
      // onClick={() => { setCounsellingPortal(true) }}
      ><a href="" target='' style={{ textDecoration: 'none', color: 'white' }}>AICEE 2025 Counseling Refunds ( Click here) </a></button></center>
      {/* ><a href="https://aicee.in/refund-login" target='_blank' style={{ textDecoration: 'none', color: 'white' }}>AICEE 2025 Counseling Refunds ( Click here) </a></button></center> */}
    </Next>
  )
}

const Next = styled.main`
.counsellingButton{
  margin: 1rem;
  padding: 0.5rem 2rem;
  color: white;
  background-color: #042e2e;
}
`

export default ExitMessage