import React from 'react'
import styled from 'styled-components'
import { MDBCard, MDBCardBody, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { axios } from 'axios'
import { message } from "antd";


function DocumentUpload(props) {
    const onSubmit = async (e, docs) => {
        // alert('helo')
        const file = e.target.files[0]
        const formData = new FormData();
        formData.append("pdf", file);
        // Make an API call to upload the file
        fetch(`/api/v1/document/upload/${docs}`, {
            method: 'POST',
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                // Handle the response from the API
                // alert('success')
                message.success('Doucment Uploaded Successfully')
                props.onClick()
                console.log(data);
            })
            .catch((error) => {
                // Handle errors from the API call
                message.error('Doucment Uploaded Faied')
                console.error(error);
            });
    }


    return (
        <>
            <MDBCard>
                <div style={{ padding: '5px 8px' }}>
                    <Mess>
                        <div>
                            <div>
                                <div className='proceedButton' style={{ backgroundColor: '#042e2e' }}>Documents Upload </div>
                                <hr></hr>
                            </div>
                            <div style={{ marginTop: '-1.5rem' }}>
                                <>
                                    <div responsive className="containers">
                                        <div className="header-table">
                                            <div>Required Documents</div>
                                            <div>Document Specifications</div>
                                            <div>Upload</div>
                                        </div>
                                        <div className="header-table-card">
                                            <div>
                                                Class Xth Marksheet <span style={{fontWeight:'bold',color:'red'}}> * </span>
                                            </div>
                                            <div>
                                                <div className='format'>
                                                    <p>Document Format: </p>
                                                    <p>PDF</p>
                                                </div>
                                                <div className='format' style={{ marginTop: '-0.5rem' }}>
                                                    <p>Min Size (KB): </p>
                                                    <p>10</p>
                                                </div>
                                                <div className='format' style={{ marginTop: '-0.5rem' }}>
                                                    <p>Max Size (KB): </p>
                                                    <p>500</p>
                                                </div>
                                            </div>
                                            <div>
                                                <input className='choosefile' type='file' accept='.pdf' onChange={(e) => { onSubmit(e, "tenthCert") }} />
                                                {/* <img  alt='10th'/> */}
                                                {
                                                    props.educationDetails &&
                                                    <iframe src={`https://counselling.aicee.in/api/v1/download/${props.educationDetails.tenthCert}`} width='100%'></iframe>
                                                }
                                                {/* <embed  width="800px" height="2100px" /> */}
                                            </div>
                                        </div>
                                        <div className="header-table-card">
                                            <div>
                                                Aadhaar Card <span style={{fontWeight:'bold',color:'red'}}> * </span> <span style={{ color: 'red' }}> ( Upload Both Sides of Aadhaar In One Single PDF ) </span>
                                            </div>
                                            <div>
                                                <div className='format'>
                                                    <p>Document Format: </p>
                                                    <p>PDF</p>
                                                </div>
                                                <div className='format' style={{ marginTop: '-0.5rem' }}>
                                                    <p>Min Size (KB): </p>
                                                    <p>10</p>
                                                </div>
                                                <div className='format' style={{ marginTop: '-0.5rem' }}>
                                                    <p>Max Size (KB): </p>
                                                    <p>500</p>
                                                </div>
                                            </div>
                                            <div>
                                                <input className='choosefile' type='file' accept='.pdf' onChange={(e) => { onSubmit(e, "aadharDocs") }} />
                                                {
                                                    props.educationDetails &&
                                                    <iframe src={`https://counselling.aicee.in/api/v1/download/${props.educationDetails.aadharDocs}`} width='100%'></iframe>
                                                }

                                            </div>
                                        </div>
                                        {/* <div className="header-table-card">
                                            <div>
                                                Aadhaar Card <span style={{ color: 'red' }}>( Back Side )  </span>
                                            </div>
                                            <div>
                                                <div className='format'>
                                                    <p>Document Format: </p>
                                                    <p>PDF</p>
                                                </div>
                                                <div className='format' style={{ marginTop: '-0.5rem' }}>
                                                    <p>Min Size (KB): </p>
                                                    <p>10</p>
                                                </div>
                                                <div className='format' style={{ marginTop: '-0.5rem' }}>
                                                    <p>Max Size (KB): </p>
                                                    <p>500</p>
                                                </div>
                                            </div>
                                            <div>
                                                <input className='choosefile' type='file' accept='.pdf' />
                                            </div>
                                        </div> */}
                                        <div className="header-table-card">
                                            <div>
                                                Class XIIth Marksheet <span style={{ color: 'red' }}>( Optional If you are Appearing in 2024 and your result hasn't been declared yet )  </span> <br></br>

                                            </div>
                                            <div>
                                                <div className='format'>
                                                    <p>Document Format: </p>
                                                    <p>PDF</p>
                                                </div>
                                                <div className='format' style={{ marginTop: '-0.5rem' }}>
                                                    <p>Min Size (KB): </p>
                                                    <p>10</p>
                                                </div>
                                                <div className='format' style={{ marginTop: '-0.5rem' }}>
                                                    <p>Max Size (KB): </p>
                                                    <p>500</p>
                                                </div>
                                            </div>
                                            <div>
                                                <input className='choosefile' type='file' accept='.pdf' onChange={(e) => { onSubmit(e, "twelveCert") }} />
                                                {
                                                    props.educationDetails &&
                                                    <iframe src={`https://counselling.aicee.in/api/v1/download/${props.educationDetails.twelveCert}`} width='100%'></iframe>
                                                }
                                            </div>
                                        </div>
                                        <div className="header-table-card">
                                            <div>
                                                Other Documents <span style={{ color: 'red' }}> ( If you have appeared for any of the Entrance Examination other than AICEE - 2024 ) </span>
                                            </div>
                                            <div>
                                                <div className='format'>
                                                    <p>Document Format: </p>
                                                    <p>PDF</p>
                                                </div>
                                                <div className='format' style={{ marginTop: '-0.5rem' }}>
                                                    <p>Min Size (KB): </p>
                                                    <p>10</p>
                                                </div>
                                                <div className='format' style={{ marginTop: '-0.5rem' }}>
                                                    <p>Max Size (KB): </p>
                                                    <p>500</p>
                                                </div>
                                            </div>
                                            <div>
                                                <input className='choosefile' type='file' accept='.pdf' onChange={(e) => { onSubmit(e, "otherDocs") }} />
                                                {
                                                    props.educationDetails &&
                                                    <iframe src={`https://counselling.aicee.in/api/v1/download/${props.educationDetails.otherDocs}`} width='100%'></iframe>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            </div>
                            <p style={{ textAlign: 'center', color: 'red', fontWeight: '600', fontSize: '13px', marginTop: '0.4rem' }}>The Last date to Upload the documents & Pay Seat Acceptance Fee is extended till 27th March 2025 11:00 PM.</p>
                            {/* // payment start // */}
                            <div className="payment-form" >
                                <form method="POST" name="customerData" action="/ccavRequestHandler">
                                    <table style={{ display: 'none' }} width="40%" height="100" border='1' align="center">
                                        <caption>
                                            <font size="4" color="blue"><b>Integration Kit</b></font>
                                        </caption>
                                    </table>
                                    <table width="40%" height="100" border='1' align="center" style={{ border: 'none' }}>
                                        <tr style={{ display: 'none' }}>
                                            <td>Parameter Name:</td>
                                            <td>Parameter Value:</td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td colspan="2">Compulsory information</td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td>Merchant Id</td>
                                            <td><input type="text" name="merchant_id" id="merchant_id" value="241419" /> </td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td>Order Id</td>
                                            <td><input type="text" name="order_id" value={props.data.examination_details.application_no} /></td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td>Billing Zip:</td>
                                            <td><input type="text" name="billing_zip" value={props.data.personalDetails.pincode} /></td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td>Billing Address:</td>
                                            <td><input type="text" name="billing_address"
                                                value={props.data.personalDetails.addressLine1} /></td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td>Billing Tel:</td>
                                            <td><input type="text" name="billing_tel" value={props.data.mobileNo} />
                                            </td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td>Currency</td>
                                            <td><input type="text" name="currency" value="INR" /></td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td>Amount</td>
                                            <td><input type="text" name="amount" value="15000" /></td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td>Redirect URL</td>
                                            <td><input type="text" name="redirect_url"
                                                value="https://counselling.aicee.in/ccavResponseHandlerSeatAccept" />
                                            </td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td>Cancel URL</td>
                                            <td><input type="text" name="cancel_url"
                                                value="https://counselling.aicee.in/ccavResponseHandlerSeatAccept" />
                                            </td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td>Language</td>
                                            <td><input type="text" name="language" id="language" value="EN" /></td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td colspan="2">Billing information(optional):</td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td>Billing Name</td>
                                            <td><input type="text" name="billing_name" value={props.data.name} /></td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td>Billing Address:</td>
                                            <td><input type="text" name="billing_address"
                                                value={props.data.personalDetails.addressLine1} /></td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td>Billing City:</td>
                                            <td><input type="text" name="billing_city" value={props.data.personalDetails.city} /></td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td>Billing State:</td>
                                            <td><input type="text" name="billing_state" value={props.data.personalDetails.state} /></td>
                                        </tr>

                                        <tr style={{ display: 'none' }}>
                                            <td>Billing Country:</td>
                                            <td><input type="text" name="billing_country" value="India" />
                                            </td>
                                        </tr>

                                        <tr style={{ display: 'none' }}>
                                            <td>Billing Email:</td>
                                            <td><input type="text" name="billing_email"
                                                value={props.data.email} /></td>
                                        </tr>

                                        <tr>
                                            <td></td>
                                            <td>
                                                <center><button disabled={!props.educationDetails.tenthCert || !props.educationDetails.aadharDocs} type="submit" className='proceedButton' style={{ backgroundColor: '#042e2e', marginTop: '-2rem' }}>Proceed to Pay seat Acceptance of INR 15,000 /-</button></center>
                                            </td>
                                        </tr>
                                    </table>
                                </form>
                            </div>
                            {/* // payment end // */}

                        </div>


                    </Mess>
                </div>
            </MDBCard>
        </>

    )
}

const Mess = styled.main`
*{
    border: none;
}

.proceedButton{
    padding: 8px 15px;
    color: white;
    font-weight: 500;
    border-radius: 5px;
    margin: 10px 0;
    text-align: center;
}
    .heading{
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        color: #042e2e;
    }
    hr{
        margin-top: -0.7rem;
    }
   
    .para{
        margin-top: -16px;
    }
    .header-table{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        background-color: #72787c;
        margin: 2rem 0 0 0;
        color: white;
        font-weight: 600;
        padding: 0.3rem 0.5rem;
    }
    .header-table-card{
        display: grid;
        grid-template-columns: repeat(3,1fr); 
    }
    .format{
        display: flex;
        justify-content: space-between;
    }
    .header-table-card>div{
       /* width: 33%; */
       border: 1px solid black;
       padding: 0.5rem 1rem  ;
       font-weight: 500; 
    }
    .choosefile{
        width: 100%;
        border: 2px solid #72787c;
    }
    @media only screen and (max-width: 600px){
        .headingMess{
        /* margin-left: 2rem; */
        margin-top: -0.7rem;
    }


    }


`

export default DocumentUpload