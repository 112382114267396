import React from 'react'
import Dashboard from '../components/spotDashboard/Dashboard'
const Declaration = () => {
  return (
    <div>
        <Dashboard />
        <div style={{ fontFamily: 'Montserrat, sans-serif' }}>
            <div className="card">
                <div className="card-header " style={{ fontWeight: "bold", color: 'white', textAlign: 'center', backgroundColor: '#042e2e' }}>
                    {/* General Instructions related with AICEE 2022 Counselling */}
                    Student's Declaration for AICEE 2025 Counseling
                </div>
                <div className="card-body">
                    <blockquote
                        className="blockquote mb-0"
                        style={{ fontSize: "1rem", color: "black", fontWeight: "400" }}
                    >
                        <div className="alert alert-light" style={{ padding: '7px 1rem', fontSize: '0.9rem', color: 'black' }} role="alert">
                            <li> Students Needs to login with Valid Credentials .</li>
                            <li> Student will be able to Confirm their seats from the given choice of Colleges as per Vacancy in respective Institutions / Program .</li>
                        </div>
                        <div className="alert alert-light" style={{ padding: '7px 1rem', marginTop: '-1.6rem', fontSize: '0.9rem', color: 'black' }} role="">
                            {/* <li>Only those Students will be Allowed to take Part in the counseling process ,who had
                          registered themselves and Paid the counseling Fees as per their registered program .</li> */}
                            <li style={{ fontWeight: 'bold', fontSize: '0.9rem', textAlign: 'center', listStyle: 'none' }}>
                                {/* Students need to pay counseling fee as per given Table : */}
                                A Counseling Fee as per given Table will be charged
                            </li>
                        </div>

                        <div>
                            <table style={{ width: '98%', margin: 'auto', color: 'black', fontSize: '0.7rem', marginTop: '-0.8rem', borderRadius: '7px' }} className="table  table-bordered border-dark">
                                <thead>
                                    <tr>
                                        <th scope="col">Sl.No</th>
                                        <th scope="col">Courses</th>
                                        <th scope="col">Fee</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* <tr>
                              <th scope="row">1</th>
                              <td>Polytechnic</td>
                              <td>INR <br /> 5300 /- </td>
                            </tr> */}
                                     <tr>
                                        <th scope="row">1</th>
                                        <td>Engineering (B-Tech / BE), Bio Technology ( Bsc. BioTechnology / B-Tech BioTechnology ) , B.Pharmacy , Agriculture ( Bsc. Agriculture / B-Tech Agriculture ) , Bsc Datascience, 	B.Pharmacy , Bsc.Nursing , Bsc.Agriculture , Bio-Tech, BBA , BCA , B.Design , LLB (BA / B.Com / B.sc / BBA / BCA) , Mass Communication & Journalism, MBA/PGDM</td>
                                        <td>INR 3,800 /-</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="alert alert-light" style={{ padding: '7px 1rem', fontSize: '0.9rem', color: 'black', textAlign: 'justify' }} role="">
                            <li>The Above said Counseling fees is Completely Adjustable in 1st Year of Tution Fee. In case of Withdrawal from the Counselling process. INR 1800 /- will be refunded to student's Account.</li>
                            <li>A Student can fill Minimum 25 & Maximum 200 colleges Option in Respective Rounds .</li>
                            <li>Do not Share Your Login Id & Password with anyone .</li>
                        </div>

                        <div className="alert alert-light" style={{ marginTop: '-0.8rem', fontSize: '0.9rem' }} role="">
                           {/* <p style={{ textAlign: 'center', color: 'red', fontWeight: '600' }}>Last Date for Counselling Fee Payment is Over</p> */}
                           {/* <p style={{ textAlign: 'center', color: 'red', fontWeight: '600' }}>Last date to pay counseling Registration fee and Participate in AICEE 2024 Counselling Rounds are 12th Feb, 2024 11:00 PM.</p> */}
                           {/* <p style={{ textAlign: 'center', color: 'red', fontWeight: '600',marginTop:'-12px' }}>To View the Complete list of Participating Colleges , Click on " Menu " at the top left corner of this page.</p> */}
                            {/* <span style={{textAlign:'center',color:'red',width:'80%',margin:'auto'}}>Last Date for payment counselling fee (Round 2) : 12th Aug 2022 11:00 PM</span> */}
                            {/* Registration for Counselling (Round 2) extended till 6th Aug, 2022, 11:00 PM. */}

                            {/* <figure className="text-center">
                                <blockquote className="blockquote">
                                    <p style={{ color: 'red', fontSize: '0.9rem', fontWeight: '500' }}>Last Date is Over </p>
                                </blockquote>
                            </figure> */}

                            <div className="form-check" style={{ width: '100%', margin: 'auto' }}>
                                <center>

                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="checked"
                                        checked
                                        // disabled='disabled'
                                        style={{ border: 'none', border: '1px solid black', alignItems: "left" }}
                                    />
                                    <label
                                        class="form-check-label"
                                        for="gridCheck"
                                        style={{ fontSize: "0.9rem", color: "black", margin: 'auto', textAlign: 'justify', fontWeight: 'bold' }}

                                    >

                                      Here I undertake that All the Information provided by me during Application are true & Correct to the best of my knowledge . In Case of any of the Information found to be Incorrect at any Stage my Alloted seat can be cancelled. I further want to say that i have read all the Instructions & Refund rules carefully and I agree to Participate in the Counselling Rounds of AICEE.
                                    </label>
                                </center>

                            </div>
                        </div>



                       

                    </blockquote>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Declaration