import React from 'react'

const ImpDates = () => {
  return (
    <div>
      <div style={{overflow:'scroll'}}>
        <iframe src='https://aicee.s3.ap-south-1.amazonaws.com/counselling/Important+Dates++Related+with+AICEE+2025+Counseling.pdf' width="100%" style={{margin:'auto'}} height="800px" frameborder="0"></iframe>
        </div>
    </div>
  )
}

export default ImpDates