

import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Dashboard from './components/adminDashboard/Dashboard'
import AdminLogin from './components/adminDashboard/AdminLogin'
import Home from './pages/Home'
import Registration from './Registration/Registration'
import Registrations from './Registration/Testing'
import './app.css'
import './scss/style.scss'
import ParticipatingColleges from './pages/ParticipatingColleges'
import Contact from './pages/Contact'
import RefundRules from './pages/RefundRules'
//             <Route exact path="/login" name="Login Page" element={<Login />} />
import ImpDates from './pages/ImpDates'
import InstructionRules from './pages/InstructionRules'
import Result from './pages/Result'
import MyPayments from './pages/MyPayments'
import PrivateRoute from './pages/PrivateRoute'
import Logout from './pages/Logout'
import PaymentTest from './pages/PatmentTest'
import PaymentSlip from './pages/PaymentSlip'

import PrivateRouteAdmin from './components/adminDashboard/PrivateRouteAdmin'
import SeatConfirmation from './pages/seatConfirmation/SeatConfirmation'
import LockCollegeChoice from './components/adminDashboard/lockCollege/index'
import ThankYouPage from './pages/ThankYouPage'
import PendingCollegeAllotment from './components/adminDashboard/pendingCollegeAllotment/index'
import SpotCollegeAllotment from './components/adminDashboard/spot/SpotCollegeAllotment'
import ViewCollegeAllotment from './components/adminDashboard/viewCollegeAllot/index'
import CounsellingPayment from './components/adminDashboard/payment/index'
import MissingStudent from './components/adminDashboard/MissingStudent'
import SpotLogin from './spotRound/landing/Registration'
//  SPOT COMPONENTS
import { SpotSidebar } from './spotRound/Index'
import TestingRegistration from './spotRound/landing/TestingRegistration'
import SpotPrivateRoute from './spotRound/PrivateRoute/SpotPrivateRoute'
import SpotLogout from './spotRound/SpotLogout'
import Index from './components/adminDashboard/totalStudents'
import SpotLockedCollege from './components/adminDashboard/spot/SpotLockedCollege'
import SpotAllotedCollege from './components/adminDashboard/spot/SpotAllotedCollege'
import Declaration from './pages/Declaration'
import SeatAccept from './pages/seatConfirmation/SeatAccept'
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Registration />} />
          <Route path='/testing' element={<Registrations />} />
          <Route path='/logout' element={<Logout />} />
          <Route path='/spot/logout' element={<SpotLogout />} />
          <Route path='/test' element={<PaymentTest />} />
          <Route path='/dashboard' element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          } />
          <Route path='/dashboard/declaration' element={
            <PrivateRoute>
              <Declaration />
            </PrivateRoute>
          } />
          <Route path='/dashboard/participating-colleges' element={
            <PrivateRoute>
              <ParticipatingColleges />
            </PrivateRoute>
          } />
          <Route path='/dashboard/support&contacts' element={
            <PrivateRoute>
              <Contact />
            </PrivateRoute>
          } />
          <Route path='/dashboard/refund-rules' element={
            <PrivateRoute>
              <RefundRules />
            </PrivateRoute>
          } />
          <Route path='/dashboard/instructions' element={
            <PrivateRoute>
              <InstructionRules />
            </PrivateRoute>
          } />
          <Route path='/dashboard/important-Dates' element={
            <PrivateRoute>
              <ImpDates />
            </PrivateRoute>
          } />
          <Route path='/dashboard/rank-card' element={
            <PrivateRoute>

              <Result />
            </PrivateRoute>
          } />
          <Route path='/dashboard/my-payments' element={
            <PrivateRoute>
              <MyPayments />
            </PrivateRoute>
          } />
          <Route path='/admin-dashboard' element={
            <PrivateRouteAdmin>
              <Dashboard />
            </PrivateRouteAdmin>
          } />
          <Route path='/admin-dashboard/college-allotment-pending' element={
            <PrivateRouteAdmin>
              <PendingCollegeAllotment />
            </PrivateRouteAdmin>
          } />
          <Route path='/admin-dashboard/spot/college-allotment-pending' element={
            <PrivateRouteAdmin>
              <SpotCollegeAllotment />
            </PrivateRouteAdmin>
          } />
          <Route path='/admin-dashboard/spot/college-alloted' element={
            <PrivateRouteAdmin>
              <SpotAllotedCollege />
            </PrivateRouteAdmin>
          } />
          <Route path='/admin-dashboard/spot/lock-college' element={
            <PrivateRouteAdmin>
              <SpotLockedCollege />
            </PrivateRouteAdmin>
          } />
          <Route path='/admin-dashboard/student/view' element={
            <PrivateRouteAdmin>
              <Index />
            </PrivateRouteAdmin>
          } />
          <Route path='/admin-dashboard/college-allotment-pending' element={
            <PrivateRouteAdmin>
              <PendingCollegeAllotment />
            </PrivateRouteAdmin>
          } />
          <Route path='/admin-dashboard/college-allotment-view' element={
            <PrivateRouteAdmin>
              <ViewCollegeAllotment />
            </PrivateRouteAdmin>
          } />
          <Route path='/admin-dashboard/counselling-payment' element={
            <PrivateRouteAdmin>
              <CounsellingPayment />
            </PrivateRouteAdmin>
          } />
          <Route path='/admin-dashboard/counselling-lock-college' element={
            <PrivateRouteAdmin>
              <LockCollegeChoice />
            </PrivateRouteAdmin>
          } />
          <Route path='/admin-dashboard/student/missing' element={
            <PrivateRouteAdmin>
              <MissingStudent />
            </PrivateRouteAdmin>
          } />
          <Route path='/student/dashboard/seat-confirmation' element={
            <PrivateRoute>
              <SeatConfirmation />
            </PrivateRoute>
          } />
          <Route path='/student/dashboard/seat-accepted' element={
            <PrivateRoute>
              <SeatAccept />
            </PrivateRoute>
          } />
          <Route exact path="/admin/login" element={<AdminLogin />} />
          <Route exact path="/admin/lock-college-choice" element={<LockCollegeChoice />} />
          <Route exact path="/student/thank-you-for-participating-college-choice" element={<ThankYouPage />} />
          <Route exact path="/spot/student/dashboard" element={
            <SpotPrivateRoute>
              <SpotSidebar />
            </SpotPrivateRoute>
          } />
          <Route exact path="/spot/student/dashboard/payment/:download" element={
            <SpotPrivateRoute>
              <SpotSidebar />
            </SpotPrivateRoute>
          } />
          <Route exact path="/spot/student/login" element={<SpotLogin />} />
          <Route exact path="/spot/student/testing/login" element={<TestingRegistration />} />
        </Routes>
      </BrowserRouter>



    </>
  )
}

export default App
