import React, { useState } from 'react'
import styled from 'styled-components'
import newGif from '../assets/images/newGif.gif'
function ImpUpdate() {
  const [showModal, setShowModal] = useState(false);
  function handleAlertClick() {
    setShowModal(!showModal);
  }

  return (
    <Update>
      <div className='Header' style={{ textAlign: 'center' }}>
        News and Update Related with AICEE 2025 Counselling Process
      </div>
      <div className='row'>
        <div className='col-md-6'>
          <a
            href='https://aicee.s3.ap-south-1.amazonaws.com/counselling/Important+Dates++Related+with+AICEE+2025+Counseling.pdf'
            target='_blank'
            rel='noopener noreferrer'>
            <p className='marqueePara'>
              Important Dates Related with AICEE 2025 Counseling
              <i className="fa fa-file text-danger" style={{ fontSize: "15px" }}></i>
              &nbsp;
              <img style={{ marginLeft: '-3px' }} src={newGif} alt="new" />
            </p>
          </a>
        </div>
        <div className='col-md-6'>
          <a
            href='https://aicee.s3.ap-south-1.amazonaws.com/counselling/COLLEGE+CHOICE+FILLING+%26+ALLOTMENT+GUIDE.pdf'
            target='_blank'
            rel='noopener noreferrer'>
            <p className='marqueePara text-capitalize'>
              College choice filling & allotment guide
              <i className="fa fa-file text-danger ms-3" style={{ fontSize: "18px" }}></i>
              <img src={newGif} alt="new" />
            </p>
          </a>
        </div>
        <div className='col-md-6'>
          <a
            href='https://aicee.s3.ap-south-1.amazonaws.com/counselling/AICEE+2025+REFUND+RULES.pdf'
            target='_blank'
            rel='noopener noreferrer'>
            <p className='marqueePara text-capitalize'>
              AICEE 2025 REFUND RULES
              <i className="fa fa-file text-danger ms-3" style={{ fontSize: "18px" }}></i>
              <img src={newGif} alt="new" />
            </p>
          </a>
        </div>
      </div>
      <div>
      </div>

    </Update>
  )
}


const Update = styled.main`
    a{
        list-style: none;
        text-decoration: none;
    }
    .notice{
      
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }
    .notice>div{
      width:50%
    }
    @media only screen and (max-width: 600px){
      .notice{
      
      display: grid;
      
      gap: -25px;
    }
    .notice>div{
      width:105%;
      margin-bottom:-1.1rem;
    }
}
    .Header{

        border: 1px solid red;
        background-color: #e35d26;
        color: white;
        padding: 10px 15px;
        border-radius: 10px 10px;
        font-weight: 600;
    }
   
    .marqueePara{
        background-color: #e0e0e0;
        margin-top: 10px;
        border-radius: 5px;
        padding: 7px 10px;
        font-size: 13px;
        color: black;
    }
    .marqueePara>img{
        margin-left: 10px;
    }
    .modal {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: block;
}

.modal-dialog {
  position: relative;
  margin: 10% auto;
  max-width: 400px;
}

.modal-content {
  border: none;
  border-radius: 0;
}

.modal-body {
  padding: 20px;
  text-align: center;
}
.modal-body>img{
  width: 150px;
}

.modal-footer {
  display: flex;
  justify-content: center;
}

.btn-primary {
  background-color: #1f1463;
  border-color: #007bff;
}

    
`
export default ImpUpdate